import React, { useRef, useState, useEffect } from 'react'

import classes from '@src/styles/Clients.module.css'
import classNames from 'classnames'

import Cargill from '@src/assets/clients/cargill.svg'
import Sunnova from '@src/assets/clients/sunnova.svg'
import THD from '@src/assets/clients/thehomedepot.svg'
import Trek from '@src/assets/clients/trek.svg'
import InstaCart from '@src/assets/clients/instacart.svg'
import SaresRegis from '@src/assets/clients/sares-regis.svg'
import { useMediaQuery } from 'react-responsive'

function Clients({ isB2b }: { isB2b?: boolean }) {
  const [showAllSectors, setShowAllSectors] = useState(false)
  const [isMounted, setIsMounted] = useState(false)
  const [isSmallScreen, setIsSmallScreen] = useState(false)

  const sectorsColumns = [
    {
      title: 'Public Sector',
      items: [
        'Department of Justice',
        'Drug Enforcement Administration',
        'Office of Emergency Services',
        '100+ public entities and school districts',
      ],
    },
    {
      title: 'Regulated & Hazardous',
      items: ['Trek Bikes', 'Cushman & Wakefield', 'Ace Hardware', 'ATS Products'],
    },
    {
      title: 'Alcohol, Sanitizer, Beverage',
      items: ['Home Depot', 'Paulaner', 'Young Living', 'RL Liquidators'],
    },
    {
      title: 'Solar Panels & E-waste',
      items: ['Sunnova', 'Sungrow', 'Kiewit', 'TotalEnergies'],
    },
    {
      title: 'Bulk & Junk',
      items: ['Instacart', 'Sares Regis Group', 'Blick Art', 'Doordash'],
    },
    {
      title: 'Dumpster',
      items: ['Cargill', 'Colrich', 'Weee!', 'Fairfield Inn'],
    },
  ]

  const smallScreenMatch = useMediaQuery({ maxWidth: 480 })
  
  useEffect(() => {
    setIsMounted(true)
    setIsSmallScreen(smallScreenMatch)
  }, [smallScreenMatch])

  const columnsToShow = (!isMounted || (isMounted && isSmallScreen && !showAllSectors)) 
    ? 1 
    : sectorsColumns.length

  return (
    <section className={classNames(classes.container, { [classes.containerB2b]: isB2b })}>
      <div className={classes.mainContent}>
        <h4 className={classNames(classes.heading, { [classes.headingB2b]: isB2b })}>
          {isB2b ? 'Partnerships' : 'Proudly Serving'}
        </h4>
        {isB2b && (
          <p className={classes.description}>
            Sustainability and superior service is achieved when we work together with the brightest
            minds in the industry. Dimension employs a network of thousands of providers ready to
            assist your trash, waste disposal and recycling needs.
          </p>
        )}
        <div className={classes.logoContainer}>
          <img className={classes.logoNormal} src={InstaCart.src} alt='InstaCart logo' />
          <img className={classes.logoNormal} src={Cargill.src} alt='Cargill logo' />
          <img className={classes.logoNormal} src={Sunnova.src} alt='Sunnova logo' />
          <img
            className={classNames(classes.logoNormal, classes.homeDepotLogo)}
            src={THD.src}
            alt='THD logo'
          />
          <img className={classes.logoNormal} src={Trek.src} alt='Trek logo' />
          <img className={classes.logoNormal} src={SaresRegis.src} alt='SaresRegis logo' />
        </div>

        <div className={classes.sectorsContainer}>
          <div className={classes.sectorTitles}>
            <div className={classes.sectorItemsContainer}>
              {sectorsColumns
                .slice(0, columnsToShow)
                .map((sector) => (
                  <div key={sector.title} className={classes.sectorItemsColumn}>
                    <h6 className={classes.sectorTitle}>{sector.title}</h6>
                    {sector.items.map((item) => (
                      <div key={`${sector.title}-${item}`} className={classes.sectorItem}>
                        {item}
                      </div>
                    ))}
                  </div>
                ))}
            </div>
            {isMounted && isSmallScreen && (
              <div className={classes.buttonContainer}>
                <button
                  className={classes.primaryButton}
                  onClick={() => setShowAllSectors(!showAllSectors)}
                >
                  {showAllSectors ? 'See Less' : 'See all our clients'}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Clients